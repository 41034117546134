var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          attrs: {
            model: _vm.formData,
            "label-position": "right",
            "label-width": "80px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "名称:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.name,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "name", $$v)
                  },
                  expression: "formData.name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "分类:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.cateId,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "cateId", _vm._n($$v))
                  },
                  expression: "formData.cateId"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "链接类型:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.mode,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "mode", _vm._n($$v))
                  },
                  expression: "formData.mode"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "链接:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.url,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "url", $$v)
                  },
                  expression: "formData.url"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "参数:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.params,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "params", $$v)
                  },
                  expression: "formData.params"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "小程序APPID:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.mpAppid,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "mpAppid", $$v)
                  },
                  expression: "formData.mpAppid"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "小程序路径:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.mpPath,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "mpPath", $$v)
                  },
                  expression: "formData.mpPath"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "是否发送客服消息:" } },
            [
              _c("el-switch", {
                attrs: {
                  "active-color": "#13ce66",
                  "inactive-color": "#ff4949",
                  "active-text": "是",
                  "inactive-text": "否",
                  clearable: ""
                },
                model: {
                  value: _vm.formData.inMpMessage,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "inMpMessage", $$v)
                  },
                  expression: "formData.inMpMessage"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "客服消息参数:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.mpMessageParam,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "mpMessageParam", $$v)
                  },
                  expression: "formData.mpMessageParam"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("保存")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.back } },
                [_vm._v("返回")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }